import React, { Component } from 'react';
import axios from 'axios';
import { createStore } from 'redux';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import rootReducer from './state/rootReducer';
import Documentation from './documentation/Documentation';
import './App.css';
import PatientMenu from './patients/PatientMenu';
import { receivePatients } from './state/patientsState';


import { injectIntl, defineMessages } from "react-intl";

const store = createStore(rootReducer);

class App extends Component {
  componentDidMount() {
    axios.get('https://medixflow.de/api/v1/patient')
      .then(response => store.dispatch(receivePatients(response.data)))
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const {intl:{formatMessage}} = this.props;
    return (
      <div>
      <header className="locale">
            <div className="languages">
              <a href="/?locale=en">English</a>
              <a href="/?locale=de">Deutsch</a>
            </div>
      </header>
      <Provider store={store}>
        <Router>
          <div className="App">
            <PatientMenu />
            <Route path="/patients/:id" component={Documentation} />
          </div>
        </Router>
      </Provider>
      </div>
    );
  }
}

export default injectIntl(App);
